var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "back-bar" },
    [
      _vm.step <= 0
        ? [
            _c("div", { on: { click: _vm.goBack } }, [
              _c("i", { staticClass: "el-icon-arrow-left" }),
              _c("div", [_vm._v("返回")]),
            ]),
          ]
        : [
            _c("div", { on: { click: _vm.prevStep } }, [
              _c("i", { staticClass: "el-icon-arrow-left" }),
              _c("div", [_vm._v("返回上一步")]),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }